/* eslint-disable import/no-cycle */
import api from '../api';

export const recoveryPassword = async (props) => {
  try {
    const response = await api.post('/user/recovery-password', props);

    return response.data;
  } catch (e) {
    return {
      message: e.response?.data.message || e.response?.data.errorMessage || JSON.stringify(e),
    };
  }
};

export const isErrorHandlerPassword = (response) => {
  return typeof response === 'object' && response !== null && 'message' in response && !('data' in response);
};
