/* eslint-disable import/no-cycle */
import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jwt-decode';
import { refreshTokenAction } from '../redux/authentication/actionCreator';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const clearAndRedirectAuth = async () => {
  try {
    await refreshTokenAction();
  } catch (error) {
    localStorage.clear();
    Cookies.remove('logedIn');
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    window.location.href = '/';
  }
};

api.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      localStorage.clear();
      Cookies.remove('logedIn');
      Cookies.remove('access_token');
      window.location.href = '/';
    }

    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      clearAndRedirectAuth();
    }

    if (error.code === 'ERR_NETWORK') {
      clearAndRedirectAuth();
    }

    // if (error.code === 'ERR_NETWORK') {
    //   localStorage.clear();
    //   Cookies.remove('logedIn');
    //   Cookies.remove('access_token');
    //   logoutSuccess(false);
    //   window.location.href = '/';
    // }
    return Promise.reject(error);
  },
);

api.interceptors.request.use((config) => {
  const configRequest = config;

  const now = new Date().getTime() / 1000;

  const token = Cookies.get('access_token');

  if (token) {
    const decodedAuthToken = jwt(token);

    if (decodedAuthToken.exp < now) {
      clearAndRedirectAuth();
      return configRequest;
    }

    if (!configRequest.headers.Authorization) {
      configRequest.headers.Authorization = `Bearer ${token}`;
    }
  }

  return configRequest;
});

export default api;
