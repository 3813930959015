/* eslint-disable import/no-cycle */
import Cookies from 'js-cookie';
import api from '../api';

export const refreshToken = async () => {
  try {
    const refreshTokenValue = Cookies.get('refresh_token');

    if (!refreshTokenValue) {
      throw new Error('No refresh token found');
    }

    const response = await api.post('/auth/refresh-token', { refreshToken: refreshTokenValue });

    if (!response.data || !response.data.data || !response.data.data.IdToken) {
      throw new Error('Invalid response from refresh token endpoint');
    }

    const newAccessToken = response.data.data.IdToken;

    // Update the access token in cookies
    Cookies.set('access_token', newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    // Remove tokens from cookies as they might be invalid
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    throw error;
  }
};
